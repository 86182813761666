import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion, AccordionSummary, Grid } from '@material-ui/core';
import { Typography } from '@mui/material';
import { getOpenSurveysForUserPortfolio } from '../modules/surveydata';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';

export default function Surveys(props) {
    const dispatch = useDispatch();
    const features = useSelector((state) => state.settingsdata.features);
    const surveysInUse = features != null ? features.surveysInUse : false;
    const [surveys, setSurveys] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        async function getSurveys() {
            var resp = await getOpenSurveysForUserPortfolio()(dispatch);
            if (resp.length > 0) {
                const sortedSurveys = orderBy(resp, ['updateDate'], ['desc']);
                setSurveys(sortedSurveys);
            }
        }
        if (surveysInUse) {
            getSurveys();
        }
    }, [surveysInUse, dispatch]);

    const openSurvey = (id) => {
        props.history.push('/survey/' + id);
    };

    if (surveys.length === 0) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {t('survey.noSurveys')}
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container>
            {surveys.map((survey) => (
                <Grid item xs={12} key={survey.id}>
                    <Accordion onClick={() => openSurvey(survey.id)}>
                        <AccordionSummary>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <h3>{survey.name}</h3>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('survey.modifiedAt', {
                                            timeStamp: dayjs.utc(survey.updateDate).local().format('DD.MM.YYYY HH:mm'),
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                    </Accordion>
                </Grid>
            ))}
        </Grid>
    );
}
